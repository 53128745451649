// extracted by mini-css-extract-plugin
export var averages = "userlist-module--averages--YBLcI";
export var card = "userlist-module--card--4FguI";
export var card_container = "userlist-module--card_container--lEGFB";
export var me = "userlist-module--me--THlqm";
export var name = "userlist-module--name--Khk-r";
export var no_hover = "userlist-module--no_hover--lJYqk";
export var options = "userlist-module--options--uc8Eg";
export var reset = "userlist-module--reset--OtkBI";
export var score = "userlist-module--score--npe3R";
export var self = "userlist-module--self--2FEfF";
export var show_scores = "userlist-module--show_scores--r2WBC";
export var two_columns = "userlist-module--two_columns--042J7";
export var user = "userlist-module--user--IYf4C";
export var user_list = "userlist-module--user_list--ZIT9P";